<!--
 * @Author: 艾斯
 * @Description: 表单容器
 * @Date: 2022-06-30 10:04:43
 * @LastEditTime: 2022-07-01 16:25:37
 * @FilePath: /sksaas-web/src/pages/ClassFee/Fee/addFee.vue
-->
<template>
  <div class="addFee">
    <div class="content">
      <div class="tab-content">
        <a-tabs :tabBarGutter="0" :tabBarStyle="{ padding: '8px 8px' }" size="small" v-model="tabKey">
          <a-tab-pane style="padding: 12px 6px;" key="1" tab="基本信息">
            <basicInfo ref="basicInfo" :infoData="infoData"></basicInfo>
          </a-tab-pane>
          <a-tab-pane style="padding: 12px 6px;" key="2" tab="限制使用">
            <useInfo ref="useInfo" :infoData="infoData"></useInfo>
          </a-tab-pane>
          <a-tab-pane style="padding: 12px 6px;" key="3" tab="其它">
            <otherInfo ref="otherInfo" :infoData="infoData"></otherInfo>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>

    <div class="footer">
      <a-button style="width: 120px; text-align: center; margin-right: 20px;"  @click="onClose">
        关闭
      </a-button>
      <a-button type="primary" style="width: 120px; text-align: center;"   @click="_action()">
        确定
      </a-button>
    </div>
  </div>
</template>

<script>
import basicInfo from 'pages/ClassFee/Fee/basicInfo'
import useInfo from 'pages/ClassFee/Fee/useInfo'
import otherInfo from 'pages/ClassFee/Fee/otherInfo'
export default {
  components: { basicInfo, useInfo, otherInfo },
  data() {
    return {
      tabKey: '1',
      infoData: {},
      checkContract:true,
      msg:''
    }
  },
  props: {
    formInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created() {
    this.infoData = { ...this.formInfo }
     if (this.formInfo.Id){
        this.checkInContract(this.formInfo.Id)
     }
    
  },
  methods: {
    onClose() {
      this.$emit('visible', false)
    },

			checkInContract(id){
						this.$axios.get(80810004, {ClassFeeId:id}, res => {
							 if(res.data.code==1){
                     this.checkContract =false
								      this.msg=res.data.msg
               
							 } 
                 
						})
			},
    // 提交表单
    async _action() {
      let result1, result2, result3
      try {
        result1 = await this.$refs.basicInfo.onSubmit()
      } catch (error) {
        // console.error(error)
      }
      try {
        result2 = await this.$refs.useInfo.onSubmit()
      } catch (error) {
        // console.error(error)
        result2 = 'err'
      }
      try {
        result3 = this.$refs.otherInfo.onSubmit()
      } catch (error) {
        result3 = 'err'
      }

      console.log(result1, result2, result3)
      if (result1) {
        let form = { ...result1 }
        if (result2 !== 'err') {
          if (!result2) {
            return false
          }
          form = { ...form, ...result2 }
        }

        if (result3 !== 'err') {
          form = { ...form, ...result3 }
        }
        for (const key in form) {
          if (typeof form[key] === 'boolean') {
            form[key] = +form[key]
          }
          if (typeof form[key] === 'object') {
              if(key=="Effect"||key=="SchoolId"){
                form[key] = form[key].join()
              }
 
            if (form[key] === 'all') {
              form[key] = ''
            }
          }
        }
           console.log(form)
        // 如果是编辑
        if (this.formInfo.Id) {
          form.Id = this.formInfo.Id
           
         
          if(!this.checkContract){

              this.$message.success(this.msg)
              return false
          }
        }
     
        this.$axios.post(300011, { ...form }, res => {
          if (res.data.code == 1) {
            this.$message.success(res.msg)
            this.$emit('visible', 'actClose')
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.addFee {
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: left;
    background: #fff;
    border-radius: 0 0 4px 4px;
    z-index: 10000;
  }
}
</style>
