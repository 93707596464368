<template>
  <div class="useInfo">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 11 }">
      <a-form-model-item label="有效期(月)" prop="Validity">
        <a-input-number v-model="form.Validity" allowClear @change="handleVChange('Validity')"  style="width:415px;" />
      </a-form-model-item>
      <a-form-model-item label="有效期(天)" prop="ValidityDays">
        <a-input-number v-model="form.ValidityDays" allowClear @change="handleVChange('ValidityDays')" style="width:415px;"  />
      </a-form-model-item>
      <a-form-model-item label="可冻结次数(次)">
        <a-input-number v-model="form.FreezeNum" allowClear  style="width:415px;"  />
      </a-form-model-item>
      <a-form-model-item label="可冻结时长（天）">
        <a-input-number v-model="form.FreezeDays" allowClear  style="width:415px;" />
      </a-form-model-item>
      <a-form-model-item label="适用合同" prop="IsNewMember">
        <a-select allowClear placeholder="请选择" v-model="form.IsNewMember">
          <a-select-option value="Y">新单</a-select-option>
          <a-select-option value="N">续课单</a-select-option>
          <a-select-option value="NY">所有</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="适用范围" prop="EfectArea">
        <a-select allowClear placeholder="请选择" v-model="form.EfectArea" @change="EfectAreaChange">
          <a-select-option value="全体">全体</a-select-option>
          <a-select-option value="指定部门">指定部门</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false" prop="SchoolId" v-show="isShowDepartment">
        <a-tree-select v-model="form.SchoolId" :tree-data="treeData" placeholder="请选择" allowClear tree-checkable> </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="购买途径">
        <a-select allowClear placeholder="请选择" v-model="form.Type">
          <a-select-option v-for="item in typeList" :key="item.value">{{ item.label }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="临时会员收费标准">
        <a-select allowClear placeholder="请选择" v-model="form.IsTemporary">
          <a-select-option :value="0">非临时会员</a-select-option>
          <a-select-option :value="1">临时会员</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="收费标准类型" prop="IsCycle">
        <a-select allowClear placeholder="请选择" v-model="form.IsCycle" @change="IsCycleChange">
          <a-select-option :value="0">课次制</a-select-option>
          <a-select-option :value="1">课时制</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="合同类型" v-show="isShowNameType">
        <a-radio-group v-model="form.NameType">
          <a-radio value="">
            无
          </a-radio>
          <a-radio v-for="item in nameTypeList" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="上课周期类型" v-show="isShowNameType">
        <a-select allowClear placeholder="请选择" v-model="form.CycleType" @change="CycleTypeChange">
          <a-select-option :value="0">不限</a-select-option>
          <a-select-option :value="1">每天</a-select-option>
          <a-select-option :value="2">每周</a-select-option>
          <a-select-option :value="3">每月</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false" v-show="isShowNameType && isShowWeek" prop="CycleDays">
        <a-select allowClear placeholder="请选择" v-model="form.CycleDays" mode="multiple" optionFilterProp="label">
          <a-select-option v-for="item in weeks" :key="item.value" :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="线下最大上课节数" v-show="isShowNameType" prop="DownCycleLessonNum">
        <a-input-number v-model="form.DownCycleLessonNum" allowClear :min="0" />
      </a-form-model-item>
      <a-form-model-item label="线上最大上课节数" v-show="isShowNameType" prop="UpCycleLessonNum">
        <a-input-number v-model="form.UpCycleLessonNum" allowClear :min="0" />
      </a-form-model-item>
      <a-form-model-item label="正式可选课程" prop="StrClassName">
        <a-select
          allowClear
          placeholder="请选择"
          v-model="form.StrClassNameIdList"
          mode="multiple"
          optionFilterProp="label"
          ref="StrClassNameIdList"
          @change="handleChange('StrClassNameIdList')"
        >
          <a-select-option value="all" label="所有课程">
            所有课程
          </a-select-option>
          <a-select-option v-for="item in curriculumList" :key="item.Id" :label="item.Name">
            {{ item.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="赠送可选课程"  prop="StrOtherClassName">
        <a-select
          allowClear
          placeholder="请选择"
          v-model="form.StrOtherClassNameIdList"
          mode="multiple"
          optionFilterProp="label"
          ref="StrOtherClassNameIdList"
          @change="handleOChange('StrOtherClassNameIdList')"
        >
          <a-select-option value="all" label="所有课程">
            所有课程
          </a-select-option>
          <a-select-option v-for="item in curriculumList" :key="item.Id" :label="item.Name">
            {{ item.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false">
        <a-checkbox v-model="form.IsExtension">
          不可延期
        </a-checkbox>
        <a-checkbox v-model="form.IsAddLesson">
          不可赠课
        </a-checkbox>
        <a-checkbox v-model="form.IsRefund">
          不可退费
        </a-checkbox>
        <a-checkbox v-model="form.IsOpenNow">
          立即开启
        </a-checkbox>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { weeks } from '@/utils/enum.js'
export default {
  name: 'rentQi',
  data() {
    return {
      weeks,
      form: {
        IsNewMember: 'Y',
        EfectArea:'',
        SchoolId: undefined,
        Type: 'all',
        TmallItemId: '',
        Note: '',
        IsTemporary: 0,
        IsCycle: 0,
        NameType: '',
        CycleType: 0,
        CycleDays: [],
        DownCycleLessonNum: '',
        UpCycleLessonNum: '',
        StrClassNameIdList: [],
        StrOtherClassNameIdList: [],
        Validity: '',
        ValidityDays: '',
        IsExtension: false,
        IsAddLesson: false,
        IsRefund: false,
        IsOpenNow: false
      },
      rules: {
        IsNewMember: [{ required: true, message: '请选择合适合同', trigger: 'blur' }],
        EfectArea: [{ required: true, message: '请选择适用范围', trigger: 'blur' }],
        IsCycle: [{ required: true, message: '请选择收费标准类型', trigger: 'blur' }],
        StrClassName: [{ required: true, message: '请选择正式可选课程', trigger: 'blur' }],
        StrOtherClassName: [{ required: true, message: '请选择赠送可选课程', trigger: 'blur' }],
        Validity: [{ required: true, message: '请输入有效期', trigger: 'blur' }],
        // ValidityDays: [{ required: true, message: '请输入有效期', trigger: 'blur' }]
      },
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      treeData: [],
      isShowDepartment: false,
      isShowWeek: false,
      isShowNameType: false,
      curriculumList: [],
      nameTypeList: [],
      typeList: []
    }
  },
  props: {
    infoData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created() {
    // 如果是编辑数据，修正表单数据类型
    if (this.infoData.Id) {
      // console.log(this.form)
      for (const key in this.form) {
        if (typeof this.form[key] === 'boolean') {
          this.form[key] = !!this.infoData[key]
        } else if (key === 'SchoolId' || key === 'CycleDays') {
          this.form[key] = this.infoData[key] ? this.infoData[key].split(',') :[]
        } else if (key === 'StrOtherClassNameIdList' || key === 'StrClassNameIdList') {
          this.form[key] = this.infoData[key] ? this.infoData[key].split(',') : ['all']
        } else if (typeof this.form[key] === 'boolean') {
          this.form[key] = !!this.infoData[key]
        } else {
          this.form[key] = this.infoData[key]
        }
      }
      this.EfectAreaChange(this.form.EfectArea)
      this.IsCycleChange(this.form.IsCycle)
      this.CycleTypeChange(this.form.CycleType)
       let rules = { ...this.rules }
       
         delete rules.StrClassName
         delete rules.StrOtherClassName
          this.rules = { ...rules }
    }
    // 获取部门
    this.$axios.get(
      5836120,
      {
        userId: this.userinfo.uid
      },
      res => {
        if (res.data.code == 1) {
          this.treeData = res.data.data.schoollist
          // this.StatusList = res.data.data.statuslist
        }
      }
    )
    this.getCurriculumList()
    this.getNameTypeList()
    this.getTypeList()
  },
  methods: {
    // 适用范围选择事件
    EfectAreaChange(val) {
      let rules = { ...this.rules }
      this.isShowDepartment = val === '指定部门'
      if (val !== '指定部门') {
        this.form.SchoolId = []
        if (rules.SchoolId) {
          this.$refs.ruleForm.clearValidate('SchoolId')
        }

        delete rules.SchoolId
      } else {
        rules.SchoolId = [{ required: true, message: '请选择部门', trigger: 'change' }]
      }
      this.rules = { ...rules }
    },
    // 选择上课周期类型事件
    CycleTypeChange(val) {
      let rules = { ...this.rules }
      this.isShowWeek = val === 2
      if (val !== 2) {
        this.form.CycleDays = []
        if (rules.CycleDays) {
          this.$refs.ruleForm.clearValidate('CycleDays')
        }
        delete rules.CycleDays
      } else {
        rules.CycleDays = [{ required: true, message: '请选择周几', trigger: 'change' }]
      }
      this.rules = { ...rules }
    },
    //收费标准类型选择事件
    IsCycleChange(val) {
      let rules = { ...this.rules }
      this.isShowNameType = val === 1
      if (val !== 1) {
        this.form.NameType = ''
        this.form.CycleType = 0
        this.form.DownCycleLessonNum = ''
        this.form.UpCycleLessonNum = ''
        if (rules.DownCycleLessonNum && rules.UpCycleLessonNum) {
          this.$refs.ruleForm.clearValidate('DownCycleLessonNum')
          this.$refs.ruleForm.clearValidate('UpCycleLessonNum')
        }
        delete rules.DownCycleLessonNum
        delete rules.UpCycleLessonNum
      } else {
        rules.DownCycleLessonNum = [{ required: true, message: '请输入线下最大上课节数', trigger: 'blur' }]
        rules.UpCycleLessonNum = [{ required: true, message: '请输入线上最大上课节数', trigger: 'blur' }]
      }
      this.rules = { ...rules }
    },
     handleVChange(key) {
      if (this.form[key]) {
        let newKey = key === 'Validity' ? 'ValidityDays' : 'Validity'
        let rules = { ...this.rules }
        if (!rules[key]) {
          rules[key] = [{ required: true, message: '请输入有效期', trigger: 'blur' }]
        }
        if (rules[newKey]) {
          delete rules[newKey]
        }
        this.$refs.ruleForm.clearValidate(newKey)
        this.rules = { ...rules }
      }
    },
    handleChange(key) {
         let rules = { ...this.rules }
         if (rules.StrClassName) {
          this.$refs.ruleForm.clearValidate('StrClassName')
         
        }

         delete rules.StrClassName
        
            this.rules = { ...rules }
      if (this.form[key].some(item => item === 'all')) {
        this.form[key] = ['all']
        this.$refs[key].blur()
      }
    },
      handleOChange(key) {
         let rules = { ...this.rules }
         if (  rules.StrOtherClassName) {
          this.$refs.ruleForm.clearValidate('StrOtherClassName')
        }
         delete rules.StrOtherClassName
            this.rules = { ...rules }
      if (this.form[key].some(item => item === 'all')) {
        this.form[key] = ['all']
        this.$refs[key].blur()
      }
    },
    // 获取课程列表
    getCurriculumList() {
      this.$axios.get(80810012, { label: 'FeeClass' }, res => {
        if (res.data.code == 1) {
          this.curriculumList = [...res.data.data]
        }
      })
    },
    // 获取合同类型
    getNameTypeList() {
      this.$axios.get(300009, {}, res => {
        if (res.data.code == 1) {
          this.nameTypeList = [...res.data.data]
        }
      })
    },
    // 获取购买渠道
    getTypeList() {
      this.$axios.get(300010, {}, res => {
        if (res.data.code == 1) {
          this.typeList = [...res.data.data]
        }
      })
    },
    onSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            resolve(this.form)
          } else {
            reject(false)
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.useInfo {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}
</style>
