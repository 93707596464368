<!--
 * @Author: 艾斯
 * @Description: 
 * @Date: 2022-06-30 10:04:43
 * @LastEditTime: 2022-07-01 18:50:56
 * @FilePath: /sksaas-web/src/pages/ClassFee/Fee/otherInfo.vue
-->
<template>
  <div class="other_info">
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 11 }">
      <a-form-item label="协议类型">
        <a-radio-group v-model="form.PolicyType">
          <a-radio v-for="item in policyTypeList" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
     <a-form-model-item label="商品ID">
        <a-input v-model="form.TmallItemId" allowClear placeholder="出售时必填（由电商提供）" />
      </a-form-model-item>
         <a-form-model-item label="描述">
        <a-textarea v-model="form.Note" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </a-form-model-item>
      <a-form-item label="上课频次说明">
        <a-textarea v-model="form.CycleText" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="会员端显示" />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        PolicyType: 0,
        CycleText: ''
      },
      policyTypeList: []
    }
  },
  props: {
    infoData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created() {
      // 如果是编辑数据，修正表单数据类型
    if (this.infoData.Id) {
      // console.log(this.form)
      for (const key in this.form) {
        
          this.form[key] = this.infoData[key]
        
      }
    }
    this.getPolicyType()
  },
  methods: {
    getPolicyType() {
      this.$axios.get(300008, {}, res => {
        if (res.data.code == 1) {
          this.policyTypeList = res.data.data
        }
      })
    },
    onSubmit() {
      return this.form
    }
  }
}
</script>

<style scoped lang="less">
.other_info {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}
</style>
