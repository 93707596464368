<template>
  <div class="rentQi">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 11 }">
      <a-form-model-item label="课程名称" prop="Name">
        <a-input v-model="form.Name" allowClear />
      </a-form-model-item>
      <a-form-model-item label="所属课程类别" prop="ClassTypeName" >
        <a-select show-search allowClear placeholder="请选择" v-model="form.ClassId"  @change="classNameChange" >
          <a-select-option v-for="item in ClassType" :key="item.Id" :value="item.Id">
            {{ item.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="课包简介">
        <a-tree-select v-model="form.ClassFeeCategory" :tree-data="treeData2" :replaceFields="{ key: 'Id', value: 'title' }" placeholder="请选择" allowClear> </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="永不过期">
        <a-select allowClear placeholder="请选择" v-model="form.NeverExpire" @change="NeverExpireChange">
          <a-select-option :value="0">否</a-select-option>
          <a-select-option :value="1">是</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="有效期"  v-show="isShowEffect" prop="Effect">
        <a-range-picker v-model="form.Effect" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" allowClear @change="dateChange" />
      </a-form-model-item>
      <a-form-model-item label="课次" prop="LessonNum" @change="LessonNumChange">
        <a-input-number  v-model="form.LessonNum" allowClear style="width:390px;"  />&nbsp;&nbsp;次
      </a-form-model-item>
      <a-form-model-item label="标准金额" prop="StandardFee">
        <a-input-number v-model="form.StandardFee"  allowClear style="width:390px;"  />&nbsp;&nbsp;元
      </a-form-model-item>
      <a-form-model-item label="优惠金额" prop="Fee">
        <a-input-number v-model="form.Fee"  allowClear  style="width:390px;" />&nbsp;&nbsp;元
      </a-form-model-item>
      <a-form-model-item label="核算单数">
        <a-input-number v-model="form.Count" allowClear style="width:390px;"  />&nbsp;&nbsp;单
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false">
        <a-checkbox v-model="form.DisposableDeduction">
          是否一次性扣课
        </a-checkbox>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'rentQi',
  data() {
    return {
      form: {
        Name: '',
        ClassName: undefined,
        ClassId: '',
        ClassFeeCategory: [],
        NeverExpire: 1,
        Effect: [],
        LessonNum: '',
        Fee: '',
        StandardFee: '',
   
        FreezeNum: '',
        FreezeDays: '',
        Count: '',
        DisposableDeduction: false
      },
      rules: {
        Name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        ClassTypeName: [{ required: true, message: '请选择所属课程类别', trigger: 'blur' }],
        LessonNum: [{ required: true, message: '请输入课次', trigger: 'blur' }],
        StandardFee: [{ required: true, message: '请输入标准金额', trigger: 'blur' }],
        Fee: [{ required: true, message: '请输入优惠金额', trigger: 'blur' }],
      },
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      treeData2: [],
      ClassType: [],
      isShowEffect:false
    }
  },
  props: {
    infoData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created() {
    // 如果是编辑数据，修正表单数据类型
    if (this.infoData.Id) {
      console.log(this.infoData)
      for (const key in this.form) {
        if (typeof this.form[key] === 'boolean') {
          this.form[key] = !!this.infoData[key]
        } else if (typeof this.form[key] === 'number') {
          this.form[key] = +this.infoData[key]
        } else if (key === 'Effect') {
          this.form.Effect = [this.infoData.EffectFrom, this.infoData.EffectTo]
        } else {
          this.form[key] = this.infoData[key]
        }
      }
      //console.log(  this.form)
       this.classNameChange(this.form.ClassId)
       this.LessonNumChange(this.form.LessonNum)
    }
    //获取课包简介
    this.$axios.get(80810011, { label: 'FeeClass' }, res => {
      if (res.data.code == 1) {
        this.treeData2 = res.data.data
      }
    })
    //获取课程类别
    this.$axios.get(80810012, { label: 'FeeClass' }, res => {
      if (res.data.code == 1) {
        this.ClassType = res.data.data
      }
    })
  },
  methods: {
    classNameChange(val){
      let rules = { ...this.rules }
       if (!this.infoData.Id){
       if (rules.ClassTypeName) {
         
          this.$refs.ruleForm.clearValidate('ClassTypeName')
        }
       }
         delete rules.ClassTypeName
   
       this.rules = { ...rules }
    },
     LessonNumChange(val){
      let rules = { ...this.rules }
       
         delete rules.LessonNum
   
       this.rules = { ...rules }
    },
    // 日期控件值变化
    dateChange(val) {
      if (val[0]) {
        this.form.EffectFrom = val[0]
        this.form.EffectTo = val[1]
      } else {
        this.form.EffectFrom = ''
        this.form.EffectTo = ''
      }
    },
    classChange(key){
      console.log(key)
    },
     NeverExpireChange(val){
        let rules = { ...this.rules }
        if(val==0){
          this.isShowEffect=true
          rules.Effect = [{ required: true, message: '请输入有效期', trigger: 'change' }]
        }else{
           this.isShowEffect=false
           if (rules.Effect) {
            this.$refs.ruleForm.clearValidate('Effect')
          }
           delete rules.Effect
        }
      this.rules = { ...rules }
     },
    handleChange(key) {
      if (this.form[key]) {
        let newKey = key === 'Validity' ? 'ValidityDays' : 'Validity'
        let rules = { ...this.rules }
        if (!rules[key]) {
          rules[key] = [{ required: true, message: '请输入有效期', trigger: 'blur' }]
        }
        if (rules[newKey]) {
          delete rules[newKey]
        }
        this.$refs.ruleForm.clearValidate(newKey)
        this.rules = { ...rules }
      }
    },
    onSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            resolve(this.form)
          } else {
            reject(false)
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.rentQi {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}
</style>
