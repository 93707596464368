<template>
	<div class="hdTicket">
		<div class="page-header flex">
			<span style="font-size: 16px;">收费优惠管理</span>
			<div>
				<div class="screen-btn ant-menu-item-selected" @click="_makeTodo('add')">
					<a-icon type="plus-circle" />
					<span>新增</span>
				</div>
			</div>
		</div>
		<div class="page-main">
			<div class="screen-list">
				<a-form-model layout="inline" :model="souForm">
					<a-form-model-item label="名称">
						<a-input placeholder="请输入关键字" v-model="souForm.name" allowClear />
					</a-form-model-item>
					<a-form-model-item label="部门">
						<a-tree-select v-model="souForm.department" :tree-data="departmentList" placeholder="请选择"
							allowClear tree-checkable> </a-tree-select>
					</a-form-model-item>
					<a-form-model-item label="状态">
						<a-select placeholder="请选择" v-model="souForm.status" allowClear>
							<a-select-option value="">全部</a-select-option>
							<a-select-option value="0">正常</a-select-option>
							<a-select-option value="1">过期已删除</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-form-model>
				<div class="form-btn">
					<a-button @click="_resetBtn">重置条件</a-button>
					<a-button type="primary" @click="_searchBtn">开始查询</a-button>
				</div>
			</div>
			<div class="todo-content">
				<a-table :columns="columns" :dataSource="infoData" :pagination="false" bordered v-if="infoData">
					<div slot="StatusText" slot-scope="text, row">

						<span class="status-icon"
							:style="text=='已删除'?'background: #E81B1C':'background: #4199F3'"></span> {{text}}

					</div>
					<div slot="Operation" slot-scope="text, row">
						<a-icon type="form" @click="_makeTodo(row)" class="icon-btn" />
						<a-popconfirm title="您确定要恢复吗?" @confirm="_recoTableRow(row)" okText="确定" cancelText="取消">
							<a-button type="link" v-if="row.DelBoolean==1">恢复</a-button>
						</a-popconfirm>
						<a-popconfirm title="您确定要删除吗?" @confirm="_delTableRow(row)" okText="确定" cancelText="取消">
							<a-icon type="delete" class="icon-btn" v-if="row.DelBoolean==0" />
						</a-popconfirm>
					</div>
				</a-table>

				<div style="margin: 20px 0; text-align: right;">
					<a-pagination showQuickJumper :defaultCurrent="1" :total="pageTotal" @change="_PageChange" />
				</div>
			</div>

			<a-drawer :title="isEdit ? '费用信息' : '添加费用'" width="1000" :closable="false" :visible="FeeVisible"
				@close="feeClose">
				<div style="margin-bottom: 60px;">
					<addFee v-if="FeeVisible" :formInfo="formData" @visible="feeClose"></addFee>
				</div>
			</a-drawer>
		</div>
	</div>
</template>

<script>
	import addFee from 'pages/ClassFee/Fee/addFee'

	const columns = [{
			title: '名称',
			dataIndex: 'Name',
			align: 'center'
		},
		{
			title: '有效期',
			dataIndex: 'EffectText',
			align: 'center'
		},
		{
			title: '所属课程',
			dataIndex: 'ClassName',
			align: 'center'
		},
		{
			title: '课次',
			dataIndex: 'LessonNum',
			align: 'center'
		},
		{
			title: '标准金额',
			dataIndex: 'StandardFee',
			align: 'center'
		},
		{
			title: '优惠金额',
			dataIndex: 'Fee',
			align: 'center'
		},
		{
			title: '适用合同',
			dataIndex: 'IsNewMemberText',
			align: 'center'
		},
		{
			title: '适用范围',
			dataIndex: 'SchoolText',
			align: 'center'
		},
		{
			title: '购买途径',
			dataIndex: 'TypeText',
			align: 'center'
		},
		{
			title: '状态',
			dataIndex: 'DelBooleanText',
			width:'80px',
			align: 'center',
			scopedSlots: {
				customRender: 'StatusText'
			},
		},
		{
			title: '说明',
			dataIndex: 'Note',
			align: 'center'
		},
		{
			title: '图片',
			dataIndex: 'Pic',
			align: 'center'
		},
		{
			title: 'SKU',
			dataIndex: 'SKU',
			align: 'center'
		},
		{
			title: '创建人',
			dataIndex: 'CreateUserName',
			align: 'center'
		},
		{
			title: '操作',
			dataIndex: 'Operation',
			align: 'center',
			scopedSlots: {
				customRender: 'Operation'
			}
		}
	]
	export default {
		name: 'hdTicket',
		components: {
			addFee
		},
		data() {
			return {
				columns: columns,
				pageTotal: 0,
				page: 1,
				souForm: {
					name: undefined, //关键字
					status: "0", // 状态
					department: [] //部门
				},
				FeeVisible: false,
				infoData: [],
				isEdit: false,
				formData: {},
				departmentList: [],
        isCheck:0
			}
		},
		created() {
			this.getDepartmentList()
			this._info()
		},
		methods: {
			// 显示表单
			_makeTodo(row) {
     		   this.isCheck=0
				if (row !== 'add') {
       		
			
					this.isEdit = true
					this.formData = {
					...row
					}
				
				} else {
				
					this.isEdit = false
					this.formData = {}
				}
					this.FeeVisible = true	 
			},
			// 检索
			_searchBtn() {
				this.page = 1
				this._info()
			},
			// 重置
			_resetBtn() {
				this.souForm.name = undefined
				this.souForm.status = undefined
				this.souForm.department = []
			},
			checkInContract(id){
						this.$axios.get(80810004, {ClassFeeId:id}, res => {
							 if(res.data.code==1){
								this.$message.success(res.data.msg)
							 }else{
								 	this.FeeVisible = true
							 }
						})
			},
			// 删除
			_delTableRow(index) {
				this.$axios.post(
					80810003, {
						Id: index.Id
					},
					res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功')
						} else {
							this.$message.success(res.data.msg)
						}
						this._info()
					}
				)
			},
			_recoTableRow(index) {
				this.$axios.post(
					80810002, {
						Id: index.Id
					},
					res => {
						if (res.data.code == 1) {
							this.$message.success(res.data.msg)
						} else {
							this.$message.success(res.data.msg)
						}
						this._info()
					}
				)
			},

			// 分页事件
			_PageChange(page) {
				this.page = page
				this._info()
			},
			// 关闭表单
			feeClose(key) {
				if (key === 'actClose') {
					this.page = 1
					this._info()
				}
				this.FeeVisible = false
			},
			// 获取表格内容
			_info() {
				this.$message.loading('加载中...')
				let department = this.souForm.department.join()
				let data = {
					DelStatus: this.souForm.status,
					FeeName: this.souForm.name,
					SchoolId: department,
					Page: this.page,
					PageSize: 10
				}
				this.$axios.get(80810001, data, res => {
					if (res.data.code == 1) {
						this.infoData = res.data.data
						this.pageTotal = res.data.count
						this.page = this.page
					} else {
						this.pageTotal = 1
						this.infoData = []
					}
					this.$message.destroy()
				})
			},
			// 获取部门
			getDepartmentList() {
				this.$axios.get(5836120, {}, res => {
					if (res.data.code == 1) {
						this.departmentList = res.data.data.schoollist
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.hdTicket {
		width: 100%;
		background: #fff;
		box-sizing: border-box;
		font-size: 14px;

		.page-header {
			height: 55px;
			line-height: 55px;
			padding: 10px 20px;
			border-bottom: 1px solid #e1e2e3;

			.screen-btn {
				width: 100px;
				display: inline-block;
				font-size: 12px;
				margin-left: 20px;
				border-width: 1px;
				border-style: solid;
				border-radius: 20px;
				padding: 9px 0;
				line-height: 1;
				text-align: center;
				background: #fff !important;
				cursor: pointer;

				i {
					margin-right: 4px;
				}
			}
		}

		.page-main {
			padding: 20px;

			.screen-list {
				margin: 0 0 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				:deep(.ant-select-selection--single),
      :deep(.ant-form-item-control) {
        min-width: 150px;
      }

				.form-btn {
					button+button {
						margin-left: 10px;
					}
				}
			}
		}

		.icon-btn {
			cursor: pointer;
			margin-left: 10px;
			font-size: 18px;
		}

		.status-icon {
			display: inline-block;
			vertical-align: middle;
			width: 8px;
			height: 8px;
			border-radius: 50%;
		}
	}
</style>
