/*
 * @Author: 艾斯
 * @Description: 枚举
 * @Date: 2022-06-30 13:28:48
 * @LastEditTime: 2022-07-01 15:26:33
 * @FilePath: /sksaas-web/src/utils/enum.js
 */

// 周
const weeks = [
  {
    value: '0',
    label: '周日'
  },
  {
    value: '1',
    label: '周一'
  },
  {
    value: '2',
    label: '周二'
  },
  {
    value: '3',
    label: '周三'
  },
  {
    value: '4',
    label: '周四'
  },
  {
    value: '5',
    label: '周五'
  },
  {
    value: '6',
    label: '周六'
  }
]

export { weeks }
